import React, { useState } from "react";
import "./App.css";
import Landing from "./sections/Landing";
import Contact from "./sections/Contact";
import VideoBackground from "./VideoBackground";
import MegaScroll from "react-mega-scroll";
import { BiMenu } from "react-icons/bi";

const App = () => {
  const [active, setActive] = useState(0);
  const [brand, setBrand] = useState("brand-invisible");

  const onChange = (page) => {
    if (page && page>=1) {
      setBrand("brand");
    } else {
      setBrand("brand-invisible");
    }
    setActive(page);
  }


  return (
    <div className="App">
      <header className="header">
        <div className="flex pl-2 pt-2 pr-2">
          <div className={`${brand} text-lg font-bold uppercase drop-shadow ts2`}>
            QUIZBIN
          </div>
          <div className="ml-auto text-4xl font-bold uppercase mr-4 drop-shadow ts2">
            <BiMenu />
          </div>
        </div>
      </header>
      <MegaScroll onChange={onChange}>
        <div className="section-container-landing flex relative">
          <VideoBackground />
          <Landing />
        </div>
        <div className="section-container flex relative">
          <div className="flex w-full h-full pl-2 pr-2 pt-1 pb-10">
            Features
          </div>
        </div>
        <div className="section-container flex relative">
          <div className="flex w-full h-full pl-2 pr-2 pt-1 pb-10">
            Business
          </div>
        </div>
        <div className="section-container flex relative">
          <Contact />
        </div>
      </MegaScroll>
    </div>
  );
};

export default App;
