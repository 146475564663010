import React from "react";
import "./VideoBackground.css";

const VideoBackground = () => (
  <div className="video-background">
    <video autoPlay muted loop preload="auto" poster="landing.png">
      <source src="landing.webm" type="video/webm" />
      <source src="landing.mp4" type="video/mp4" />
    </video>
  </div>
);

export default VideoBackground;
